import * as Sentry from "@sentry/react";
import validator from "validator";
import { SURVEY_WORK_FLOWS } from "../reducers/survey/index.survey";
import { ThunkBaseAction } from "../store";
import { setSurveyWorkflow } from "./survey.actions";

export const SET_GOOGLE_OPTIMIZE_EXPERIMENT_ID = "SET_GOOGLE_OPTIMIZE_EXPERIMENT_ID";

export const SET_GOOGLE_OPTIMIZE_VARIANT = "SET_GOOGLE_OPTIMIZE_VARIANT";

export const SET_GOOGLE_OPTIMIZE_IS_LOADING = "SET_GOOGLE_OPTIMIZE_IS_LOADING";

export const SET_GOOGLE_OPTIMIZE_ERROR = "SET_GOOGLE_OPTIMIZE_ERROR";

export const SET_GOOGLE_OPTIMIZE_LOADING_ATTEMPTS = "SET_GOOGLE_OPTIMIZE_LOADING_ATTEMPTS";

export function setGoogleOptimizeExperimentId(experimentId: string) {
    return {
        type: SET_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
        experimentId,
    } as const;
}
export type SetGoogleOptimizeExperimentId = ReturnType<typeof setGoogleOptimizeExperimentId>;

export function setGoogleOptimizeLoadingAttempts(loadingAttempts: number) {
    return {
        type: SET_GOOGLE_OPTIMIZE_LOADING_ATTEMPTS,
        loadingAttempts,
    } as const;
}
export type SetGoogleOptimizeLoadingAttempts = ReturnType<typeof setGoogleOptimizeLoadingAttempts>;

export function setGoogleOptimizeVariant(variant: string) {
    return {
        type: SET_GOOGLE_OPTIMIZE_VARIANT,
        variant,
    } as const;
}
export type SetGoogleOptimizeVariant = ReturnType<typeof setGoogleOptimizeVariant>;

export function setGoogleOptimizeIsLoading(isLoading: boolean) {
    return {
        type: SET_GOOGLE_OPTIMIZE_IS_LOADING,
        isLoading,
    } as const;
}
export type SetGoogleOptimizeIsLoading = ReturnType<typeof setGoogleOptimizeIsLoading>;

export function setGoogleOptimizeError(isError: boolean, errorMessage: string) {
    return {
        type: SET_GOOGLE_OPTIMIZE_ERROR,
        isError,
        errorMessage,
    } as const;
}
export type SetGoogleOptimizeError = ReturnType<typeof setGoogleOptimizeError>;

export type ConfigActions =
    | SetGoogleOptimizeExperimentId
    | SetGoogleOptimizeLoadingAttempts
    | SetGoogleOptimizeVariant
    | SetGoogleOptimizeIsLoading
    | SetGoogleOptimizeError;

export const initializeGoogleOptimize =
    (experimentId: string, enforceVariant?: string | null): ThunkBaseAction =>
    (dispatch) => {
        if (enforceVariant) {
            dispatch(setVariant(enforceVariant));
        } else {
            if (experimentId) {
                dispatch(setGoogleOptimizeIsLoading(true));

                /* Set the current experiment to load from GO */
                dispatch(setGoogleOptimizeExperimentId(experimentId));

                /* Start fetching the GO app configs */
                dispatch(pollGoogleOptimizeVariants());
            } else {
                dispatch(setGoogleOptimizeIsLoading(false));
                console.info("No google optimize experiment id is given.");
            }
        }
    };

const setSurveyWorkFlowByExperimentVariant =
    (variant: string): ThunkBaseAction =>
    (dispatch) => {
        try {
            const surveyWorkFlow = SURVEY_WORK_FLOWS[variant];

            if (surveyWorkFlow) {
                dispatch(setSurveyWorkflow(surveyWorkFlow));
            } else {
                console.error(
                    `No survey with variant of ${variant} was configured. Please verify the experiment variant setup or the survey workflows`,
                );

                dispatch(setSurveyWorkflow(SURVEY_WORK_FLOWS.defaultWorkFlow));
            }
        } catch (error) {
            Sentry.captureException(
                `Failed to load survey workflow with variant name ${variant}. Verify if the given variant matches any workflows. ${error.message}`,
            );
        }
    };

const getGoogleOptimizeVariantFromCookies = function (experimentId: string) {
    const cookie = getGoogleOptimizeCookie();

    if (!cookie) {
        return null;
    }

    const cookieExperimentId = getExperimentId();
    const cookieVariationId = getVariationId();

    if (!cookieExperimentId) {
        console.error("No experimentId was found in _gaexp cookie");

        return null;
    }

    if (!cookieVariationId) {
        console.error("No variantId was found in _gaexp cookie");

        return null;
    }

    if (!validator.equals(experimentId, cookieExperimentId)) {
        console.warn(`Cookie experimentId ${cookieExperimentId} differs from configured ${experimentId}`);
    }

    return cookieVariationId;
};

const setVariant =
    (variant: string): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setGoogleOptimizeVariant(variant));

        // Set survey workflow based on experiment variant
        dispatch(setSurveyWorkFlowByExperimentVariant(variant));

        dispatch(setGoogleOptimizeError(false, null));

        dispatch(setGoogleOptimizeIsLoading(false));
    };

const setGoogleOptimizeVariantError =
    (errorMessage: string): ThunkBaseAction =>
    (dispatch) => {
        console.error(errorMessage);

        Sentry.captureException(errorMessage);

        dispatch(setGoogleOptimizeError(true, errorMessage));

        dispatch(setGoogleOptimizeVariant(null));

        dispatch(setGoogleOptimizeIsLoading(false));
    };

export const pollGoogleOptimizeVariants =
    (timeout = 50): ThunkBaseAction =>
    (dispatch, getState) => {
        /* Poll GO variants until we fail due to an error */
        if (getState().config.optimize.isError) {
            Sentry.captureException(
                `Failed to load google optimize variants. ${getState().config.optimize.errorMessage}`,
            );

            dispatch(setGoogleOptimizeIsLoading(false));

            return;
        }

        const experimentId = getState().config.optimize.experimentId;

        const attemptsToLoadGoogleOptimize = getState().config.optimize.loadingAttempts;

        const parseGoogleOptimizeCookiesOnTimeout = attemptsToLoadGoogleOptimize > 3;

        /* if loading google optimize fails for some reason during the timeout, verify if we can parse any cookies that ma be left */
        if (parseGoogleOptimizeCookiesOnTimeout) {
            const variant = getGoogleOptimizeVariantFromCookies(experimentId);

            if (variant) {
                dispatch(setVariant(variant));
            } else {
                dispatch(
                    setGoogleOptimizeVariantError(
                        `Failed to load google optimize after ${attemptsToLoadGoogleOptimize} loading attempts with a timeout of ${timeout} ms.`,
                    ),
                );
            }

            return;
        }

        dispatch(setGoogleOptimizeLoadingAttempts(attemptsToLoadGoogleOptimize + 1));

        /* Poll until we have a valid variant from the experiment */
        if (window.google_optimize !== undefined && getState().config.optimize.variant == null) {
            let variant = null;

            /* Try to fetch the variant from the google_optimize api */
            const variantFromGOApi = window.google_optimize.get(experimentId);

            if (variantFromGOApi) {
                variant = variantFromGOApi;
            } else {
                variant = getGoogleOptimizeVariantFromCookies(experimentId);
            }

            if (!variant) {
                dispatch(
                    setGoogleOptimizeVariantError(
                        `Failed to load google optimize variant from api or cookies with the experiment id ${experimentId}. Loading attempts with a timeout of ${timeout} ms. Application state is falling back to default survey workflow.`,
                    ),
                );

                return;
            }

            Sentry.setContext("googleOptimize", {
                variant,
                experimentId: getState().config.optimize.experimentId,
                loadingAttempts: attemptsToLoadGoogleOptimize,
                timeout: timeout,
            });

            dispatch(setVariant(variant));
        } else {
            setTimeout(() => dispatch(pollGoogleOptimizeVariants(timeout)), timeout);
        }
    };

function getGoogleOptimizeCookie(cookieName = "_gaexp"): string | null {
    const result = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)");
    return result ? result.pop() : null;
}

function getExperimentId(): string | null {
    const cookie = getGoogleOptimizeCookie() || "";
    const fields = cookie.split(".");
    return fields.length > 2 ? fields[2] : null;
}

function getVariationId(): string | null {
    const cookie = getGoogleOptimizeCookie() || "";
    const fields = cookie.split(".");
    return fields.length > 4 ? fields[4] : null;
}

import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import { PageProps } from "../pages/types";
import GamificationTooltip from "./GamificationTooltip";

export type OfferProps = PageProps & {
    value?: string | null;
    tooltip?: string | null;
};

export default class Offer extends Component<OfferProps> {
    render() {
        return (
            <div data-field={this.props.property}>
                <GamificationTooltip label={this.props.value} />

                <SubLabel>{this.props.tooltip}</SubLabel>

                <h3>Wie können wir dich erreichen? Teile deine Kontaktdaten mit uns!</h3>
            </div>
        );
    }
}

const SubLabel = styled.p`
    font-size: 14px;
    line-height: 1.7;
    color: ${colors.basicText};
    padding-top: 8px;
`;

import React, { Component } from "react";

import {
    SURVEY_PAGE_PROPERTIES,
    SURVEY_WORKFLOW_PAGE_INDICES,
} from "../../../../../../../redux/reducers/survey/config/survey.config";

import GamificationTooltip from "../../../new/GamificationTooltip";

import StructuredPageComponent from "../../structuredPage.component";

import { PageProps, PageStructureKeys } from "../types";

export type PersonalDataPageProps = PageProps;

export class PersonalDataPage extends Component<PersonalDataPageProps> {
    constructor(props) {
        super(props);

        this.prefillPhone();
    }

    prefillPhone() {
        if (
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.personalFinances] &&
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.personalData]
        ) {
            // We may abstract away the logic of getting the phone number from the the survey for
            // all components that need it.
            const argumentsList = {
                from: {
                    page: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                    component: "PHONE_CONTRACT_SERVICE",
                },
                to: {
                    page: SURVEY_WORKFLOW_PAGE_INDICES.personalData,
                    component: "PHONE",
                },
            };

            const argumentValue =
                this.props.workflow[argumentsList.from.page].pageStructure[argumentsList.from.component].attributes
                    .value;

            if (argumentValue) {
                const page = argumentsList.to.page,
                    component = argumentsList.to.component;

                this.props.setPageComponentValue(page, component as PageStructureKeys, argumentValue);

                this.forceUpdate();
            }
        }
    }

    render() {
        return (
            <div>
                <GamificationTooltip
                    text={
                        "Großartig,\nwir melden uns bei dir! <b>🎉</b>\n\n" +
                        "Bitte gib uns noch deine Telefonnummer bekannt. 📞"
                    }
                />

                <h2>Deine Telefonnummer</h2>

                <StructuredPageComponent
                    /* Hubspot state loading */
                    isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                    /* Change of components values */
                    setPageComponentValue={this.props.setPageComponentValue}
                    /* Change of components errors */
                    setPageComponentError={this.props.setPageComponentError}
                    /* Access to the survey config */
                    workflow={this.props.workflow}
                    /* Access to the survey version */
                    surveyVersion={this.props.surveyVersion}
                    /* Page config name for analytics */
                    pageName={SURVEY_PAGE_PROPERTIES.personalData}
                    /* Page Structure defining the elements to be rendered*/
                    pageStructure={this.props.page.pageStructure}
                    /* Convenient Current page data */
                    page={this.props.page}
                    /* Action for adding properties for user-contact creation  */
                    setProperty={this.props.setProperty}
                    /* Property list of collected user-data during the survey */
                    properties={this.props.properties}
                    /* Handler for moving to the next page */
                    onNextSurveyPage={this.props.onNextSurveyPage}
                    /* Handler for moving to the prev page */
                    onPreviousSurveyPage={this.props.onPreviousSurveyPage}
                    /* Show error after creating contact failed */
                    createContactError={this.props.createContactError}
                />
            </div>
        );
    }
}

export default PersonalDataPage;

import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import { Answer } from "../../../new/common/Answers";
import InputTypes from "../../../new/common/ComponentTypes";
import {
    expectYes,
    hasSecondBorrower,
    isBuyer,
    isNotOther,
    isNotOwnConstructionOrOther,
    isNotRefinancing,
} from "../../../new/common/PageComponentValidators";
import CommonProperties from "../../../new/common/Properties";
import type { PageStructure } from "../types";

export const pageStructure: PageStructure = {
    OCCUPATION: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Beschäftigungsart",
        property: CommonProperties.OCCUPATION,
        attributes: {
            value: null,
            options: [Answer.OCCUPATION.EMPLOYEE, Answer.OCCUPATION.INDEPENDENT, Answer.OCCUPATION.OTHER],
            required: true,
            placeholder: "Wähle Beschäftigungsart",
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle deine Beschäftigungsart.",
            invalid: null,
        },
        tooltip: "Die berufliche Situation ist für deine Finanzierung von großer Bedeutung.",
        gamification: null,
        visibility: null,
        conditional: null,
    },
    PAX: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Anzahl der Kreditnehmer",
        property: CommonProperties.PAX,
        attributes: {
            value: Answer.PAX.ONE_PERSON,
            options: [Answer.PAX.ONE_PERSON, Answer.PAX.TWO_PERSONS],
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle die Anzahl der Kreditnehmer aus.",
            invalid: null,
        },
        tooltip:
            "Wenn du einen Kredit mit deinem Partner/ deiner Partnerin aufnimmst, kann das gegebenenfalls deine Bonität verbessern und somit bessere Konditionen bringen.",
        gamification: null,
        visibility: null,
        conditional: null,
    },
    PRIMARY_PERSON_INCOME: {
        type: InputTypes.numericInput,
        label: "Monatliches Nettoeinkommen 1",
        property: CommonProperties.PRIMARY_PERSON_INCOME,
        attributes: {
            value: null,
            placeholder: "z. B. 2.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib dein monatliches Nettoeinkommen ein.",
            invalid: "Die Eingabe scheint nicht korrekt zu sein",
        },
        helperText: null,
        tooltip:
            "Das monatliche Nettoeinkommen ist zusammen mit dem Eigenmittelbetrag besonders wichtig, um die Haushaltsrechnung und die Kreditleistbarkeit zu ermitteln.",
        gamification: null,
        visibility: null,
        conditional: null,
    },
    SECONDARY_PERSON_INCOME: {
        type: InputTypes.numericInput,
        label: "Monatliches Nettoeinkommen 2",
        property: CommonProperties.SECONDARY_PERSON_INCOME,
        attributes: {
            value: null,
            placeholder: "z. B. 2.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib dein monatliches Nettoeinkommen ein.",
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                component: "PAX",
                expression: hasSecondBorrower,
            },
        ],
    },
    HOUSE_BANK: {
        type: InputTypes.textDropDown,
        label: "Deine Hausbank",
        property: CommonProperties.HOUSE_BANK,
        attributes: {
            value: null,
            options: [
                "Anadi Bank",
                "Bank99",
                "BAWAG P.S.K.",
                "easybank",
                "Erste Bank",
                "HYPO",
                "N26",
                "Oberbank",
                "Österreichische Ärzte & Apotheker Bank",
                "Raiﬀeisenbank",
                "Santander Consumer Bank",
                "start:bausparkasse",
                "UniCredit Bank Austria AG",
                "Volksbank",
                "VR Bank",
                "WSK Bank",
                "Wüstenrot Bausparkasse",
                "Andere",
            ],
            placeholder: "Wähle deine Hausbank",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Wähle deine Hausbank aus",
            invalid: null,
        },
        tooltip:
            "Wir arbeiten mit allen Top-Banken in Österreich zusammen und erhalten spezielle Konditionen. So können wir sogar für Bestandskunden noch bessere Konditionen einholen.",
        gamification: null,
        visibility: null,
        conditional: null,
    },
    CONTRACT: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Wer ist für die Erstellung des Kaufvertrages zuständig?",
        property: CommonProperties.CONTRACT,
        attributes: {
            value: null,
            options: [Answer.CONTRACT.BUYER, Answer.CONTRACT.SELLER, Answer.CONTRACT.UNKNOWN],
            placeholder: "Kaufvertragerrichter",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle wer deinen Kaufvertrag erstellt.",
            invalid: null,
        },
        tooltip:
            "Die Höhe der Vertragserrichtungskosten betragen üblicherweise 1,5 bis 3 % des Kaufpreises zuzüglich MWSt. Wir arbeiten mit Anwaltskanzleien zusammen wodurch die Vertragserrichtung für nur 1 % zzgl. MWSt abgewickelt wird.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotOwnConstructionOrOther,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotRefinancing,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotOther,
            },
        ],
        conditional: null,
    },
    INTERESTED_IN_CONTRACT_SERVICE: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Interesse an der Kaufvertragserstellung & gesamte treuhänderische Abwicklung für nur 1 % + USt + Barauslagen?",
        property: CommonProperties.INTERESTED_IN_CONTRACT_SERVICE,
        attributes: {
            value: null,
            options: [Answer.Conditional.YES, Answer.Conditional.NO],
            placeholder:
                "Interesse an der Kaufvertragserstellung & gesamte treuhänderische Abwicklung für nur 1 % + USt + Barauslagen?",
            required: false,
        },
        isError: [],
        errorMessage: {
            required: "Hast du Interesse an unserem Service?",
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                component: "CONTRACT",
                expression: isBuyer,
            },
        ],
        conditional: null,
    },
    PHONE_CONTRACT_SERVICE: {
        type: InputTypes.numericInput,
        label: "Telefonnummer",
        property: CommonProperties.PHONE_CONTRACT_SERVICE,
        attributes: {
            value: null,
            placeholder: "z. B. 00436781221017",
            required: false,
            autocomplete: "tel",
            allowLeadingZeros: true,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: "Überprüfe ob deine Telefonnummer korrekt ist.",
        },
        helperText: "Wir leiten deinen Kontakt an unsere Top-Kanzleien weiter für ein unverbindliches Angebot.",
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                component: "INTERESTED_IN_CONTRACT_SERVICE",
                expression: expectYes,
            },
        ],
        conditional: null,
    },
};

import defaultWorkFlow from "./workflows/default.workflow";

import v32 from "./workflows/v32.workflow";

/* Here you can import more survey workflows for GO experiments */
export const SURVEY_WORK_FLOWS = {
    1: v32,
    0: defaultWorkFlow,
    defaultWorkFlow,
};

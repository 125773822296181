import AppConfig from "../../../../config/AppConfig";

// Used to differentiate page types
export const SURVEY_PAGE_TYPES = {
    introPage: "introPage",
    multipleSelectionPage: "multipleSelectionPage",
    singleInputPage: "singleInputPage",
    confirmationPage: "confirmationPage",
    userContactSubmitPage: "userContactSubmitPage",
    userContactSubmitPhonePage: "userContactSubmitPhonePage",
    userContactSubmitEmailPage: "userContactSubmitEmailPage",
    userNameSubmitPage: "userNameSubmitPage",
    bankSubmitPage: "bankSubmitPage",
    creditSummaryPage: "creditSummaryPage",
    benefitsPage: "benefitsPage",
    creditConditionsPage: "creditConditionsPage",
    creditAffordabilityPage: "creditAffordabilityPage",
    dropdownSelectPage: "dropdownSelectPage",
    dashboardTransitionPage: "dashboardTransitionPage",
    /* New Survey Changes */
    welcomePage: "welcomePage",
    projectDetailsPage: "projectDetailsPage",
    keyDataPage: "keyDataPage",
    emailPage: "emailPage",
    loadingPage: "loadingPage",
    loanAmountPage: "loanAmountPage",
    personalDataPage: "personalDataPage",
    personalFinancesPage: "personalFinancesPage",
    topOffer: "topOffer",
    offer: "offer",
} as const;
export type SURVEY_PAGE_TYPES_Keys = keyof typeof SURVEY_PAGE_TYPES;
export type SURVEY_PAGE_TYPES_Values = typeof SURVEY_PAGE_TYPES[SURVEY_PAGE_TYPES_Keys];

// Used to differentiate workflow indices
export const SURVEY_WORKFLOW_PAGE_INDICES = {
    financing: "financing",
    realEstateStatus: "realEstateStatus",
    realEstatePrice: "realEstatePrice",
    currentRealEstatePrice: "currentRealEstatePrice",
    monthlyIncome: "monthlyIncome",
    initialCapital: "initialCapital",
    bank: "bank",
    benefits: "benefits",
    preferredContactType: "preferredContactType",
    contactName: "contactName",
    contactEmail: "contactEmail",
    contactPhone: "contactPhone",
    contactEmailAndPhone: "contactEmailAndPhone",
    confirmation: "confirmation",
    creditConditions: "creditConditions",
    creditAffordability: "creditAffordability",
    timeline: "timeline",
    dashboardTransition: "dashboardTransition",
    /* New Survey Changes */
    welcome: "welcome",
    keyData: "keyData",
    projectDetails: "projectDetails",
    email: "email",
    loading: "loading",
    loanAmount: "loanAmount",
    personalData: "personalData",
    personalFinances: "personalFinances",
    topOffer: "topOffer",
    historyBack: "historyBack",
    offer: "offer",
} as const;
export type SURVEY_WORKFLOW_PAGE_INDICES_Keys = keyof typeof SURVEY_WORKFLOW_PAGE_INDICES;
export type SURVEY_WORKFLOW_PAGE_INDICES_Values =
    typeof SURVEY_WORKFLOW_PAGE_INDICES[SURVEY_WORKFLOW_PAGE_INDICES_Keys];

export const SURVEY_PAGE_PROPERTIES = {
    financingType: "financingType",
    realEstateStatus: "realEstateStatus",
    realEstatePrice: "realEstatePrice",
    monthlyIncome: "monthlyIncome",
    initialCapital: "initialCapital",
    bank: "bank",
    benefits: "benefits",
    userName: "userName",
    userContactEmail: "userContactEmail",
    userContactPhone: "userContactPhone",
    confirmation: "confirmation",
    preferredContactType: "preferredContactType",
    userContact: "userContact",
    creditConditions: "creditConditions",
    creditAffordability: "creditAffordability",
    /* Middle solution */
    creditCheck: "creditCheck",
    financingTimeline: "financingTimeline",
    dashboardTransition: "dashboardTransition",
    /* New Survey Changes */
    welcome: "Willkommen",
    keyData: "Eckdaten",
    projectDetails: "Projektdetails",
    email: "Kontakt",
    loading: "Lade",
    loanAmount: "Meine Finanzierung",
    personalData: "Kontaktdaten",
    personalFinances: "Meine Daten",
    topOffer: "Top Angebot",
    offer: "Angebot",
} as const;
export type SURVEY_PAGE_PROPERTIES_Keys = keyof typeof SURVEY_PAGE_PROPERTIES;
export type SURVEY_PAGE_PROPERTIES_Values = typeof SURVEY_PAGE_PROPERTIES[SURVEY_PAGE_PROPERTIES_Keys];

/**
 * Extend this for other future survey branches
 */
export const SURVEY_PAGE_BRANCHES = {
    default: "default",
    creditAffordability: "creditAffordability",
    creditConditions: "creditConditions",
} as const;
export type SURVEY_PAGE_BRANCHES_Keys = keyof typeof SURVEY_PAGE_BRANCHES;
export type SURVEY_PAGE_BRANCHES_Values = typeof SURVEY_PAGE_BRANCHES[SURVEY_PAGE_BRANCHES_Keys];

export const SURVEY_PAGE_SUBMIT_TYP = {
    update: "update",
    create: "create",
    none: "none",
} as const;
export type SURVEY_PAGE_SUBMIT_TYP_Keys = keyof typeof SURVEY_PAGE_SUBMIT_TYP;
export type SURVEY_PAGE_SUBMIT_TYP_Values = typeof SURVEY_PAGE_SUBMIT_TYP[SURVEY_PAGE_SUBMIT_TYP_Keys];

export const initializeWorkflow = function (
    _page: SURVEY_WORKFLOW_PAGE_INDICES_Values | undefined = SURVEY_WORKFLOW_PAGE_INDICES.keyData,
) {
    /* ProductionStage should always start with the financing-question */
    if (AppConfig.isProductionStage) {
        return SURVEY_WORKFLOW_PAGE_INDICES.keyData;
    }

    return _page;
};

export const colors = {
    primary: "#4a4aff",
    basicText: "#000",
    error: "#DC2626",
    focusBg: "#F3F4F5",
    white: "#ffffff",
    gray: "#8d8b8b",
    defGray: "#4B5563",
    cyan: "#32e5d1",
    gray50: "#f9fafb",
    gray200: "#e5e7eb",
    gray300: "#d1d5db",
    gray400: "#9ca3af",
    gray500: "#6b7280",
    gray600: "#4B5563",
    gray700: "#374151",
    gray800: "#1F2937",
    gray900: "#111827",
    blue50: "#EFF6FF",
    blue100: "#DBEAFE",
    blue300: "#93C5FD",
    blue500: "#3B82F6",
    blue600: "#2563EB",
    blue700: "#1D4ED8",
    lime100: "#ECFCCB",
    lime300: "#BEF264",
    lime400: "#A3E635",
    lime500: "#84CC16",
    lime900: "#365314",
    red500: "#F87171",
};

import { Component } from "react";
import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import CallScheduler from "../../../new/CallScheduler";
import { PageProps } from "../types";

export class BookingConfirmationPage extends Component<PageProps> {
    render() {
        const email = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.email].pageStructure.EMAIL.attributes.value;

        return (
            <CallScheduler
                data={null}
                user={this.props.user}
                email={email}
                isGoodRate={true}
                isOther={false}
                notifyAboutUser={null}
            />
        );
    }
}

export default BookingConfirmationPage;

import * as Sentry from "@sentry/react";
import { CookieType } from "../../models/Cookie";
import { getUserContactsRequest, notifyAboutUserIntention, submitUserContactsRequest } from "../../service/api";
import { ThunkBaseAction } from "../store";
import { Callback } from "../types";
import { setUser } from "./hub.actions";
import { SURVEY_WORKFLOW_PAGE_INDICES } from "../reducers/survey/config/survey.config";
import validator from "validator";
import { Answer } from "../../app/elements/survey/components/new/common/Answers";

export const SET_PROPERTY = "SET_PROPERTY";

export const SUBMIT_USER_HUBSPOT = "SUBMIT_USER_HUBSPOT";

export const SET_ERROR_HUBSPOT = "SET_ERROR_HUBSPOT";

export const SET_SUCCESS_HUBSPOT = "SET_SUCCESS_HUBSPOT";

export const SET_COOKIE_PROPERTIES = "SET_COOKIE_PROPERTIES";

export const SET_IS_CONTACT_LOADING = "SET_IS_CONTACT_LOADING";

export const SET_IS_CONTACT_ERROR = "SET_IS_CONTACT_ERROR";

export const SET_IS_EXISTING_CONTACT = "SET_IS_EXISTING_CONTACT";

export const SET_APPOINTMENT_LINK = "SET_APPOINTMENT_LINK";

/**
 * Use this action to append an key-value property to properties.
 *
 * Properties are used to create contact data.
 *
 * Properties are appended objects of the form { property, value } to the properties array reduced by hubspot.reducer.js
 *
 * Existing properties do not get replaced.
 *
 * A property must match a hubspot property in order for it to be recognized for hubspot. Refer to the hubspot workflow
 * for the property list.
 */
export function setProperty(property: string, value: string) {
    return {
        type: SET_PROPERTY,
        property,
        value,
    } as const;
}
export type SetProperty = ReturnType<typeof setProperty>;

export function setIsExistingContact(isExistingContact: boolean) {
    return {
        type: SET_IS_EXISTING_CONTACT,
        isExistingContact,
    } as const;
}
export type SetIsExistingContact = ReturnType<typeof setIsExistingContact>;

export function setCookieProperties(cookies: CookieType[]) {
    return {
        type: SET_COOKIE_PROPERTIES,
        cookies,
    } as const;
}
export type SetCookieProperties = ReturnType<typeof setCookieProperties>;

function setErrorHubspot(isError: boolean, errorMessage: string) {
    return {
        type: SET_ERROR_HUBSPOT,
        isError,
        errorMessage,
    } as const;
}
export type SetErrorHubspot = ReturnType<typeof setErrorHubspot>;

function setSuccessHubspot(callback) {
    if (callback) {
        callback();
    }

    return {
        type: SET_SUCCESS_HUBSPOT,
    } as const;
}
export type SetSuccessHubspot = ReturnType<typeof setSuccessHubspot>;

function submitUserHubspot() {
    return {
        type: SUBMIT_USER_HUBSPOT,
    } as const;
}
export type SubmitUserHubspot = ReturnType<typeof submitUserHubspot>;

function setIsLoadingContact(isLoading: boolean) {
    return {
        type: SET_IS_CONTACT_LOADING,
        isLoading,
    } as const;
}
export type SetIsLoadingContact = ReturnType<typeof setIsLoadingContact>;

function setIsErrorContact(isError: boolean, errorMessage: string) {
    return {
        type: SET_IS_CONTACT_ERROR,
        isError,
        errorMessage,
    } as const;
}
export type SetIsErrorContact = ReturnType<typeof setIsErrorContact>;

function setAppointmentLink(appointmentLink: string) {
    return {
        type: SET_APPOINTMENT_LINK,
        appointmentLink,
    } as const;
}
export type SetAppointmentLink = ReturnType<typeof setAppointmentLink>;

export type HubspotActions =
    | SetProperty
    | SetIsExistingContact
    | SetCookieProperties
    | SetErrorHubspot
    | SetSuccessHubspot
    | SubmitUserHubspot
    | SetIsLoadingContact
    | SetIsErrorContact
    | SetAppointmentLink;

/**
 * Use this action to submit the user property list defined by _properties to
 * the miracl backend, resulting in the creation of user contact data for lead
 * generation in the hubspot.
 *
 * _properties must not be null, must not be empty,
 * the properties list is created automatically by the hubspot property reducer with the append action.
 *
 * @returns {function(*): void}
 */
// TODO: TS - properly define types
export const submitUserContacts =
    (properties: any, cookies: CookieType[], sendMagicLink: boolean, callback?: Callback): ThunkBaseAction =>
    (dispatch) => {
        dispatch(submitUserHubspot());

        submitUserContactsRequest({
            properties,
            cookies,
            sendMagicLink,
        })
            .then((respone) => {
                const { contactId, firstName, lastName, email, phone, created, isExistingContact } = respone.data;

                dispatch(setUser(firstName, lastName, email, phone, contactId, created));

                dispatch(setIsExistingContact(isExistingContact));

                dispatch(notifyAboutUserIntentionToSlack(false, null));

                dispatch(setSuccessHubspot(callback));
            })
            .catch((error) => {
                Sentry.captureException(`Exception raised during user contact data submit. ${error.message}`);

                dispatch(setErrorHubspot(true, error.message));

                if (callback) {
                    callback(error);
                }
            });
    };

export const notifyAboutUserIntentionToSlack =
    (callNow = false, newPhoneNumber = null) =>
    (dispatch, getState) => {
        const workflow = getState().survey.flow;

        const { currentPage } = getState().survey;

        const intention = workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.INTENTION.attributes.value;

        const isPersonalDataPage =
            currentPage && validator.equals(currentPage, SURVEY_WORKFLOW_PAGE_INDICES.personalData);

        const isHighIntentFinisher =
            isPersonalDataPage && intention && !validator.equals(intention, Answer.INTENTION.OTHER);

        const sendNotificationToSlack = callNow || isHighIntentFinisher;

        if (sendNotificationToSlack) {
            const progress = workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.PROGRESS.attributes.value;

            const progressOwnConstruction =
                workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.PROGRESS_OWN_CONSTRUCTION.attributes.value;

            const progressRefinancing =
                workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.PROGRESS_REFINANCING.attributes.value;

            const email = workflow[SURVEY_WORKFLOW_PAGE_INDICES.email].pageStructure.EMAIL.attributes.value;

            const intention = workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.INTENTION.attributes.value;

            const isHighIntentRefinancing =
                progressRefinancing &&
                validator.equals(progressRefinancing, Answer.PROGRESS.REFINANCING.CONCRETE_REFINANCING);

            const isHighIntentOwnConstruction =
                progressOwnConstruction &&
                validator.equals(progressOwnConstruction, Answer.PROGRESS.OWN_CONSTRUCTION.PROPERTY_LAND_AVAILABLE);

            const isHighIntentBuyingProperty =
                progress &&
                (validator.equals(progress, Answer.PROGRESS.OFFER_SENT) ||
                    validator.equals(progress, Answer.PROGRESS.ABOUT_TO_SEND_OFFER) ||
                    validator.equals(progress, Answer.PROGRESS.ACTIVE_VISITTING));

            const { contactId = -1 } = getState().hub.user;

            const isHighIntent = !!(
                isHighIntentBuyingProperty ||
                isHighIntentRefinancing ||
                isHighIntentOwnConstruction
            );

            const userProgress = progress || progressRefinancing || progressOwnConstruction || "Unbekannt";

            notifyAboutUserIntention(
                isHighIntent,
                callNow,
                contactId,
                newPhoneNumber,
                email,
                intention,
                userProgress,
            ).catch((error) => {
                Sentry.captureException(`Failure raised on high-intent user notification. ${error.message}`);
            });
        }
    };

export const getUserContacts =
    (callback?: Callback): ThunkBaseAction =>
    (dispatch, getState) => {
        dispatch(setIsLoadingContact(true));

        dispatch(setIsErrorContact(false, null));

        dispatch(setAppointmentLink(null));

        getUserContactsRequest({
            token: getState().session.token,
        })
            .then((response) => {
                dispatch(setIsErrorContact(false, null));

                dispatch(setIsLoadingContact(false));

                /* Set a valid appointmentscheduled link */
                const { contact } = response.data;

                if (contact && contact.meetings) {
                    dispatch(setAppointmentLink(contact.meetings.appointmentscheduled));
                }

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                Sentry.captureException(error);

                if (error.response && error.response.status === 404) {
                    dispatch(setIsErrorContact(false, null));
                } else {
                    dispatch(setIsErrorContact(true, error.message));
                }

                dispatch(setAppointmentLink(null));

                dispatch(setIsLoadingContact(false));

                if (callback) {
                    callback(error);
                }
            });
    };

export const pollMeetingsLinkAndUserContact =
    (timeout = 300): ThunkBaseAction =>
    (dispatch, getState) => {
        /* Poll until we fail due to an error */
        if (getState().hubspot.contact.isError) {
            return;
        }

        /* Poll until we have a valid appointmentLink */
        if (!getState().hubspot.contact.appointmentLink) {
            dispatch(
                getUserContacts(() => {
                    setTimeout(() => {
                        dispatch(pollMeetingsLinkAndUserContact(timeout));
                    }, timeout);
                }),
            );
        }
    };

import {
    SET_PAGE_COMPONENT_ERROR,
    SET_PAGE_COMPONENT_VALUE,
    SET_SURVEY_PAGE,
    SET_SURVEY_WORK_FLOW,
    SurveyActions,
} from "../actions/survey.actions";
import { Workflow } from "./survey/workflows/types";

import defaultSurveyWorkFlow from "./survey/workflows/default.workflow";

const initialState: Workflow = defaultSurveyWorkFlow;

const surveyReducer = (state: Workflow = initialState, action: SurveyActions): Workflow => {
    switch (action.type) {
        case SET_SURVEY_PAGE: {
            const { currentPage } = action;

            return Object.assign({}, state, {
                currentPage,
            });
        }
        case SET_SURVEY_WORK_FLOW: {
            const { workflow } = action;

            return Object.assign({}, state, {
                ...workflow,
            });
        }
        case SET_PAGE_COMPONENT_ERROR: {
            const { isError, page, component } = action;

            state.flow[page].pageStructure[component].isError = isError;

            return Object.assign({}, state, {
                ...state,
            });
        }
        case SET_PAGE_COMPONENT_VALUE: {
            const { value, page, component } = action;

            state.flow[page].pageStructure[component].attributes.value = value;

            return Object.assign({}, state, {
                ...state,
            });
        }
        default:
            return state;
    }
};

export default surveyReducer;

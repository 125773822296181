import * as Sentry from "@sentry/react";
import { Component } from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import SurveyTrackerService from "../../../../../service/gtm/surveyTracker.service";
import { colors } from "../../../../styles/constants/colors";
import { Button, ButtonCss } from "../../../../styles/newStyle";
import CheckBox from "../new/CheckBox";
import DayPicker from "../new/DayPicker";
import DropDown from "../new/DropDown";
import EmailInput from "../new/EmailInput";
import ErrorBox from "../new/ErrorBox";
import GamificationTooltip from "../new/GamificationTooltip";
import LoanAmountCardSummary from "../new/LoanAmountCardSummary";
import MultipleSelection from "../new/MultipleSelection";
import Note from "../new/Note";
import NumberInput from "../new/NumberInput";
import Offer from "../new/Offer";
import PercentageNumberInput from "../new/PercentageNumberInput";
import PhoneInput from "../new/PhoneInput";
import QuickRate from "../new/QuickRate";
import SingleSelectionGroupButton from "../new/SingleSelectionGroupButton";
import Slider from "../new/Slider";
import Textarea from "../new/Textarea";
import TextInput from "../new/TextInput";
import TimePicker from "../new/TimePicker";
import Tooltip from "../new/Tooltip";
import { PageProps, PageStructure, PageStructureKeys } from "./new/types";

export function ChildComponent(props) {
    const componentContainer = {
        percentageNumberInput: PercentageNumberInput,
        singleSelectionGroupButton: SingleSelectionGroupButton,
        textDropDown: DropDown,
        numericInput: NumberInput,
        phoneInput: PhoneInput,
        textInput: TextInput,
        textarea: Textarea,
        slider: Slider,
        emailInput: EmailInput,
        checkBox: CheckBox,
        loanAmountCardSummary: LoanAmountCardSummary,
        dayPicker: DayPicker,
        timePicker: TimePicker,
        quickRate: QuickRate,
        note: Note,
        gamification: GamificationTooltip,
        multipleSelection: MultipleSelection,
        offer: Offer,
    };

    if (props.visibility) {
        const {
            type,
            label,
            onChange,
            tooltip,
            tooltipAlignBottom,
            gamification,
            errors,
            attributes,
            value,
            pageStructure,
            workflow,
            helperText,
            pageName,
            property,
            disabled,
        } = props;

        const {
            options,
            decimalScale,
            required,
            placeholder,
            thousandSeparator,
            decimalSeparator,
            suffix,
            percentageLabel,
            autocomplete,
            allowLeadingZeros,
            hidden,
        } = attributes;

        const Component = componentContainer[type];

        // TODO Improvement: Use this child component to abstract away common behaviour such as error messages, helperText etc.
        return (
            <Component
                pageName={pageName}
                errors={errors}
                label={label}
                helperText={helperText}
                onChange={onChange}
                value={value}
                autocomplete={autocomplete}
                allowLeadingZeros={allowLeadingZeros}
                tooltip={tooltip}
                tooltipAlignBottom={tooltipAlignBottom}
                gamification={gamification}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                hidden={hidden}
                options={options}
                decimalScale={decimalScale}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                suffix={suffix}
                percentageLabel={percentageLabel}
                pageStructure={pageStructure}
                workflow={workflow}
                property={property}
            />
        );
    }

    return null;
}

export type StructuredPageComponentProps = Omit<PageProps, "getTopOffer"> & {
    pageStructure: PageStructure;
    pageName: string;
};

export class StructuredPageComponent extends Component<StructuredPageComponentProps> {
    state = {
        isInputError: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    nextSurveyPage = () => {
        const { pageIndex, context } = this.props.page;

        const { defaultNextPage } = context;

        this.props.onNextSurveyPage({
            nextPage: defaultNextPage,
            currentPage: pageIndex,
        });
    };

    prevSurveyPage = () => {
        const { pageIndex, context } = this.props.page;

        const { defaultPrevPage } = context;

        this.props.onPreviousSurveyPage({
            prevPage: defaultPrevPage,
            currentPage: pageIndex,
        });
    };

    onSubmitSurveyPage = (e) => {
        e.preventDefault();

        if (this.validateChildComponent()) {
            this.setState({
                isInputError: false,
            });

            this.nextSurveyPage();

            return;
        }

        this.setState({
            isInputError: true,
        });
    };

    renderNavButtons = () => {
        const { meta } = this.props.page;

        const { ui } = meta;

        const { showNextButton, showPrevButton } = ui;

        const nextButtonText = meta?.text?.nextButton || "Weiter";

        const navigationButtonToolTip = (meta?.text?.navigationButtonToolTip || null) as string | null;

        return (
            <>
                {showNextButton && (
                    <>
                        <ButtonNavWrapper>
                            {showPrevButton && (
                                <BackButton type={"button"} onClick={this.prevSurveyPage} className="back-btn">
                                    Zurück
                                </BackButton>
                            )}
                            {showNextButton && (
                                <Button
                                    blue
                                    type="submit"
                                    disabled={this.props.isLoadingHubspotSubmit}
                                    className="submit-btn"
                                >
                                    {nextButtonText}
                                    {this.props.isLoadingHubspotSubmit && (
                                        <Oval
                                            wrapperClass="loader"
                                            color="#fff"
                                            secondaryColor="#fff"
                                            height={22}
                                            width={22}
                                        />
                                    )}
                                </Button>
                            )}
                        </ButtonNavWrapper>
                        {navigationButtonToolTip && (
                            <Tooltip value={navigationButtonToolTip} color="green" arrowPosLeft="220px" />
                        )}
                        {!navigationButtonToolTip && <SafetyInfo>Deine sichere Verbindung</SafetyInfo>}
                    </>
                )}
            </>
        );
    };

    getComponentValue = (_page, _component) => {
        return this.props.workflow[_page].pageStructure[_component].attributes.value;
    };

    isComponentVisible = (_visibility) => {
        return _visibility && Array.isArray(_visibility)
            ? _visibility.every((triple) => {
                  const { page, expression, component } = triple;

                  const value = this.getComponentValue(page, component);

                  const shouldBeVisible = expression(value);

                  return shouldBeVisible;
              })
            : true;
    };

    isComponentDisabled = (disabledConditional) => {
        if (disabledConditional && Array.isArray(disabledConditional)) {
            return disabledConditional.every((rule) => {
                const { expression, parameters } = rule;

                const data = parameters.map((item) => {
                    return this.getComponentValue(item.page, item.component);
                });

                return expression(...data);
            });
        }

        return false;
    };

    render() {
        const { pageName, surveyVersion, page } = this.props;

        const { pageIndex, progressStep } = page;

        const parentPage = Object.keys(this.props.pageStructure);

        const children = parentPage.map((pageComponent, idx) => {
            const component = this.props.pageStructure[pageComponent];

            const showComponent = this.isComponentVisible(component.visibility);

            const isDisabled = this.isComponentDisabled(component.disabledConditional);

            return (
                <ChildComponent
                    // Improvement TODO: Introduce selectors to abstract away the data access from the child components.
                    pageStructure={this.props.pageStructure}
                    workflow={this.props.workflow}
                    errors={component.isError}
                    visibility={showComponent}
                    disabled={isDisabled}
                    type={component.type}
                    key={idx}
                    pageName={pageName}
                    label={component.label}
                    onChange={({ value, question, key }) => {
                        /* Support for multiple selection element */
                        const eventQuestion = question || component.label;

                        const eventValue = key ? value[key].value : value;

                        SurveyTrackerService.trackQuestionChangeEvent(
                            surveyVersion,
                            pageName,
                            eventQuestion,
                            eventValue,
                        );

                        this.props.setPageComponentValue(pageIndex, pageComponent as PageStructureKeys, value);

                        this.forceUpdate();
                    }}
                    value={this.props.workflow[pageIndex].pageStructure[pageComponent].attributes.value}
                    attributes={component.attributes}
                    tooltip={component.tooltip}
                    helperText={component.helperText}
                    tooltipAlignBottom={component.tooltipAlignBottom}
                    gamification={component.gamification}
                    property={component.property}
                />
            );
        });

        return (
            <form className={`survey-${progressStep} ${pageIndex}`} onSubmit={this.onSubmitSurveyPage} noValidate>
                {children}
                {this.state.isInputError && <ErrorBox error={"Bitte überprüfe ob alle Eingaben korrekt sind."} />}
                {this.props.createContactError && !this.state.isInputError ? (
                    <ErrorBox error={this.props.createContactError} />
                ) : null}
                {this.renderNavButtons()}
            </form>
        );
    }

    isComponentInputValid = (conditional) => {
        if (conditional && Array.isArray(conditional)) {
            return conditional.every((rule) => {
                const { expression, parameters } = rule;
                const data = parameters.map((item) => {
                    return this.getComponentValue(item.page, item.component);
                });

                return expression(...data);
            });
        }

        return true;
    };

    validateChildComponent = () => {
        const { page } = this.props;

        const { pageIndex } = page;

        let noErrorsAtPage = true;

        const allErrors = [];

        for (const [idx, component] of Object.entries(this.props.pageStructure)) {
            // Improvement TODO: maybe its smart to have a visible flag that is updated instead of calc. everytime the visibility flag
            const isComponentVisible = this.isComponentVisible(component.visibility);
            const isComponentDisabled = this.isComponentDisabled(component.disabledConditional);
            const inputIsRequired = component.attributes.required;
            const inputIsEmpty =
                component.attributes.value == null ||
                component.attributes.value === undefined ||
                String(component.attributes.value).length === 0;

            /* Holding errors such as required or invalid value */
            const inputElementErrors: string[] = [];

            /* Validate empty but required inputs */
            if (isComponentVisible && inputIsRequired && !isComponentDisabled) {
                if (inputIsEmpty) {
                    /* Push error required to stack label the component for empty inputs */
                    inputElementErrors.push(component.errorMessage.required || "Bitte fülle dieses Feld aus.");
                } else {
                    const isInputInvalid = !this.isComponentInputValid(component.conditional);

                    if (isInputInvalid) {
                        /* Push error required to stack label the component for invalid inputs */
                        inputElementErrors.push(component.errorMessage.invalid || "Bitte überprüfe deine Eingabe.");
                    }
                }
            }

            const atLeastOneError = inputElementErrors.length > 0 && noErrorsAtPage;

            if (atLeastOneError) {
                noErrorsAtPage = false;
            }

            allErrors.push(inputElementErrors);

            this.props.setPageComponentError(pageIndex, idx as PageStructureKeys, inputElementErrors);
        }

        if (!noErrorsAtPage) {
            // log errors to sentry
            Sentry.setContext("PageInput", {
                page,
                pageIndex,
                errors: allErrors.join(","),
            });

            Sentry.captureException(`${page}:PageValidationError`);
        }

        return noErrorsAtPage;
    };
}

const ButtonNavWrapper = styled.div`
    display: flex;
    margin: 42px 0 10px;
`;

const BackButton = styled.button`
    ${ButtonCss};
    background: transparent;
    border: 2px solid transparent;
    color: ${colors.blue600};
    font-weight: 400;
    margin-right: 15px;
    outline: 0;
    padding: 0;
    width: 160px;
`;

const SafetyInfo = styled.p`
    color: green;
    font-size: 12px;
    font-weight: 400;
    margin: 10px 6px;
    text-align: right;
`;

export default StructuredPageComponent;

import { Location } from "history";
import qs from "qs";
import { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AppConfig from "../config/AppConfig";
import { initializeGoogleOptimize } from "../redux/actions/config.actions";
import { ThunkBaseDispatch } from "../redux/store";
import AppRouter from "./app.router";

export interface AppProps extends AppDispatchProps {
    location: Location;
}

class App extends Component<AppProps & RouteComponentProps> {
    componentDidMount() {
        this.initGoogleOptimize();
    }

    initGoogleOptimize() {
        const queryArgs = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        const { experimentId = AppConfig.optimize.experimentId, enforceVariant = null } = queryArgs;

        this.props.initGoogleOptimize(experimentId ? String(experimentId) : null, String(enforceVariant));
    }

    render() {
        return <AppRouter />;
    }
}

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        initGoogleOptimize: (experimentId: string | null, enforceVariant?: string | null) =>
            dispatch(initializeGoogleOptimize(experimentId, enforceVariant)),
    };
};
type AppDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(undefined, mapDispatchToProps)(App));

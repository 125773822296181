import { History, Location } from "history";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import validator from "validator";
import { CookieType } from "../../../../../models/Cookie";
import { FinancingObject } from "../../../../../models/Engine";
import { EngineState } from "../../../../../redux/reducers/engine.reducer";
import { User } from "../../../../../redux/reducers/hub.reducer";
import {
    SURVEY_PAGE_SUBMIT_TYP,
    SURVEY_PAGE_TYPES,
    SURVEY_WORKFLOW_PAGE_INDICES,
    SURVEY_WORKFLOW_PAGE_INDICES_Values,
} from "../../../../../redux/reducers/survey/config/survey.config";
import { Workflow, WorkflowStep } from "../../../../../redux/reducers/survey/workflows/types";
import { Callback } from "../../../../../redux/types";
import { openHistoryBack } from "../../../../../service/common/helper";
import SurveyTrackerInstance from "../../../../../service/gtm/surveyTracker.service";
import { normalizeName, normalizePhoneNumber } from "../../../../../util/other";
import { SurveyNewWrapper } from "../../../../styles/newStyle";
import BankLogos from "../new/BankLogos";
import Properties from "../new/common/Properties";
import Header from "../new/Header";
import ProgressBar from "../new/ProgressBar";
import TrustFooter from "../new/TrustFooter";
import ConfirmationPage from "./new/bookingConfirmationPage";
import { EmailPage } from "./new/emailPage";
import { KeyDataPage } from "./new/keyDataPage";
import { LoanAmountPage } from "./new/loanAmountPage";
import OfferPage from "./new/offerPage";
import { PersonalDataPage } from "./new/personalDataPage";
import { PersonalFinancesPage } from "./new/personalFinancesPage";
import { ProjectDetailsPage } from "./new/projectDetailsPage";
import { TopOfferPage } from "./new/topOfferPage";
import { PageStructureItem, PageStructureKeys } from "./new/types";

// TODO: TS - properly define types
export type PageProps = {
    cookieProperties: { name: string; value: string }[];
    engine?: EngineState;
    getMeetingsLink: (timeout: number) => void;
    getTopOffer?: (financingObject: FinancingObject, callback: Callback) => any;
    history?: History;
    isLoadingHubspotSubmit?: boolean;
    location: Location;
    page: WorkflowStep;
    pageSize?: number;
    properties: any;
    setPage: (page: string) => void;
    setPageComponentError?: (
        page: SURVEY_WORKFLOW_PAGE_INDICES_Values,
        component: PageStructureKeys,
        isError?: string[],
    ) => void;
    setPageComponentValue?: (
        page: SURVEY_WORKFLOW_PAGE_INDICES_Values,
        component: PageStructureKeys,
        value: PageStructureItem["attributes"]["value"],
    ) => void;
    setProperty: (property: string, value: string) => void;
    show: boolean;
    submitUserContacts: (properties: any, cookies: CookieType[], sendMagicLink: any, callback?: Callback) => void;
    notifyAboutUser: (callNow: boolean, newPhoneNumber: string | null) => void;
    surveyVersion: string;
    t: () => void;
    topOffer?: EngineState["topOffer"];
    user?: User;
    contact?: any;
    workflow: Workflow;
};

// Improvement TODO: Adapt the propTypes so that we do not have the warnings around.
export class Page extends Component<PageProps> {
    state = {
        createContactError: undefined,
    };

    contentPages = {
        [SURVEY_PAGE_TYPES.keyDataPage]: KeyDataPage,
        [SURVEY_PAGE_TYPES.projectDetailsPage]: ProjectDetailsPage,
        [SURVEY_PAGE_TYPES.emailPage]: EmailPage,
        [SURVEY_PAGE_TYPES.loanAmountPage]: LoanAmountPage,
        [SURVEY_PAGE_TYPES.personalDataPage]: PersonalDataPage,
        [SURVEY_PAGE_TYPES.personalFinancesPage]: PersonalFinancesPage,
        [SURVEY_PAGE_TYPES.topOffer]: TopOfferPage,
        [SURVEY_PAGE_TYPES.offer]: OfferPage,
        [SURVEY_PAGE_TYPES.confirmationPage]: ConfirmationPage,
    };

    componentDidMount() {
        SurveyTrackerInstance.trackQuestionStartEvent(this.props.surveyVersion, this.props.page.property);
    }

    /**
     * Jump to survey page at the given index.
     * @param {SURVEY_WORKFLOW_PAGE_INDICES} pageIndex must not be null, must not be undefined.
     */
    jumpToSurveyPage = (pageIndex: string) => {
        if (pageIndex) {
            this.props.setPage(pageIndex);
        }
    };

    /**
     * Redirect to the given url with history.
     * @param {string }_url must not be null, must not be undefined.
     */
    linkTo = (_url) => {
        if (_url) {
            this.props.history.push(_url);
        }
    };

    calculateTotalIncome = () => {
        let totalIncome = 0;

        if (this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.personalFinances]) {
            const { pageStructure } = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.personalFinances];

            const { PRIMARY_PERSON_INCOME, SECONDARY_PERSON_INCOME } = pageStructure;

            const isPrimaryPersonAvailable = PRIMARY_PERSON_INCOME.attributes.value != null;

            const isSecondaryPersonAvailable = SECONDARY_PERSON_INCOME.attributes.value != null;

            if (isPrimaryPersonAvailable) {
                totalIncome += PRIMARY_PERSON_INCOME.attributes.value;
            }

            if (isSecondaryPersonAvailable) {
                totalIncome += SECONDARY_PERSON_INCOME.attributes.value;
            }
        }

        return totalIncome;
    };

    collectUserDataProperties = () => {
        const properties = {};

        const pages = Object.entries(this.props.workflow);

        /* Default properties from the survey */
        for (const [, pageEntry] of pages) {
            const components = Object.entries<PageStructureItem>(pageEntry.pageStructure);

            for (const [, componentEntry] of components) {
                if (componentEntry.property) {
                    properties[componentEntry.property] = {};
                    properties[componentEntry.property] = componentEntry.attributes.value;
                }
            }
        }

        return properties;
    };

    normalizeMultilineProperty = (property) => {
        let normalizedValues = "";

        if (property) {
            const keys = Object.keys(property);

            for (const key of keys) {
                if (property[key].value) {
                    normalizedValues += `${property[key].label}\n`;
                }
            }
        }

        return normalizedValues.length === 0 ? null : normalizedValues;
    };

    collectTopOfferData = () => {
        if (this.props.topOffer && this.props.topOffer.offer) {
            const { solution, application } = this.props.topOffer.offer;

            const { months, loanToValue, debtServiceToIncome } = solution;

            const { bankName = "" } = application;

            const {
                payoutAmount,
                effectiveInterestRate,
                debtAmount,
                totalCosts,
                loanAmount,
                monthsFixed,
                interestFixed,
                interestVariable,
                rate,
            } = solution.mortgage;

            const multilineProperty = {
                months,
                payoutAmount,
                effectiveInterestRate,
                debtAmount,
                totalCosts,
                loanAmount,
                monthsFixed,
                interestFixed,
                interestVariable,
                rate,
                loanToValue,
                debtServiceToIncome,
                bank: bankName,
            };

            // format multiline property to human readable string for MS guys
            const multilinePropertyString = Object.entries(multilineProperty)
                .map(([key, value]) => `${key}: ${value}`)
                .join("\n");

            return {
                ...multilineProperty,
                multilineProperty: multilinePropertyString,
            };
        }

        // return error
        return {
            multilineProperty: "No top offer data available",
            months: -1,
            payoutAmount: -1,
            effectiveInterestRate: -1,
            debtAmount: -1,
            totalCosts: -1,
            loanAmount: -1,
            monthsFixed: -1,
            interestFixed: -1,
            interestVariable: -1,
            rate: -1,
            loanToValue: -1,
            debtServiceToIncome: -1,
        };
    };

    collectUserProperties = () => {
        const properties = this.collectUserDataProperties();

        const totalIncome = this.calculateTotalIncome();

        const {
            rate,
            interestFixed,
            loanAmount,
            loanToValue,
            debtServiceToIncome,
            multilineProperty,
            months,
            monthsFixed,
            interestVariable,
        } = this.collectTopOfferData();

        const isLoanAmountUnknown = !properties[Properties.LOAN_AMOUNT];

        // This is using the loan amount from the M. E., not from the survey, because the survey is not calc. the loan amount correctly
        // Therefore pass the mortgage loan amount to the HS.
        const loan = isLoanAmountUnknown ? loanAmount : properties[Properties.LOAN_AMOUNT];

        properties[Properties.LOAN_AMOUNT] = loan;

        /* Total Amount */
        properties[Properties.TOTAL_INCOME] = totalIncome;

        /* LTV */
        properties[Properties.LTV] = loanToValue ? loanToValue : -1;

        /* DSTI */
        properties[Properties.DSTI] = debtServiceToIncome ? debtServiceToIncome : -1;

        properties[Properties.TOP_OFFER] = multilineProperty;

        // Deliver the variable rate if no fixed rates is available (special case defined by consti)
        properties[Properties.TOP_OFFER_FIXED_RATE_VALUE] = monthsFixed === 0 ? 0 : interestFixed;

        properties[Properties.TOP_OFFER_VAR_RATE_VALUE] = interestVariable;

        properties[Properties.TOP_OFFER_MONTHLY_RATE_VALUE] = rate;

        properties[Properties.TOP_OFFER_CREDIT_AMOUNT_VALUE] = loan;

        properties[Properties.TOP_OFFER_CREDIT_RUN_TIME] = months;

        properties[Properties.TOP_OFFER_CREDIT_FIXED_RUN_TIME] = monthsFixed;

        properties[Properties.TOP_OFFER_CREDIT_VAR_RUN_TIME] = months - monthsFixed;

        properties[Properties.AD_LOOKING_FOR_PROPERTY] = this.normalizeMultilineProperty(
            properties[Properties.AD_LOOKING_FOR_PROPERTY],
        );

        properties[Properties.AD_ACTIVE_VISITTING] = this.normalizeMultilineProperty(
            properties[Properties.AD_ACTIVE_VISITTING],
        );

        properties[Properties.AD_ABOUT_TO_SEND_OFFER] = this.normalizeMultilineProperty(
            properties[Properties.AD_ABOUT_TO_SEND_OFFER],
        );

        properties[Properties.AD_OFFER_SENT] = this.normalizeMultilineProperty(properties[Properties.AD_OFFER_SENT]);

        properties[Properties.AD_RESEARCH] = this.normalizeMultilineProperty(properties[Properties.AD_RESEARCH]);

        properties[Properties.FIXED_RUNTIME_IN_YEARS] =
            properties[Properties.FIXED_RUNTIME_IN_YEARS] &&
            validator.isNumeric(properties[Properties.FIXED_RUNTIME_IN_YEARS])
                ? properties[Properties.FIXED_RUNTIME_IN_YEARS]
                : -1;

        properties[Properties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS] =
            properties[Properties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS] &&
            validator.isNumeric(properties[Properties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS])
                ? properties[Properties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS]
                : -1;

        const { firstName, lastName } = normalizeName(properties[Properties.FIRST_NAME]);

        properties[Properties.FIRST_NAME] = firstName;

        properties[Properties.LAST_NAME] = lastName;

        properties[Properties.PHONE_UNFORMATTED] = properties[Properties.PHONE];

        properties[Properties.PHONE] = normalizePhoneNumber(properties[Properties.PHONE]);

        properties[Properties.SURVEY_FLAG] = false;

        properties[Properties.SURVEY_VERSION] = this.props.surveyVersion;

        // Safe remove of properties that are not needed for HS
        delete properties[Properties.PHONE_CONTRACT_SERVICE];

        delete properties[Properties.OFFER_BEST_PRICE_GUARANTEE];

        return properties;
    };

    createOrUpdateUserContact = ({ nextPage, currentPage }) => {
        const contactProperties = this.collectUserProperties();

        this.props.submitUserContacts(contactProperties, this.props.cookieProperties, false, (error: any) => {
            if (error) {
                console.error("Failed to create contact: ", error);

                const isInvalidInput = error.response.status === 400;

                this.setState({
                    createContactError: isInvalidInput
                        ? "Überprüfe ob die angegebenen Daten korrekt sind."
                        : "Wir konnten deine Anfrage gerade nicht bearbeiten. Bitte versuche es später noch einmal oder rufe uns einfach unter +4319978098 an.",
                });

                return;
            }

            const shouldCreateUser = validator.equals(this.props.page.submitType, SURVEY_PAGE_SUBMIT_TYP.create);

            const isConfirmationPageNext =
                nextPage === SURVEY_WORKFLOW_PAGE_INDICES.confirmation ||
                nextPage === SURVEY_WORKFLOW_PAGE_INDICES.topOffer;

            const { isExistingContact } = this.props.contact;

            if (shouldCreateUser) {
                SurveyTrackerInstance.trackIsRecurringUser(isExistingContact);
            }

            if (isConfirmationPageNext) {
                SurveyTrackerInstance.trackSurveySubmitAllStepsEvent(this.props.surveyVersion, this.props.workflow);
            }

            /* All other cases should show the next page as defined in the workflow. */
            this.jumpToSurveyPage(nextPage);
        });
    };

    onNextSurveyPage = ({ nextPage, currentPage }) => {
        SurveyTrackerInstance.trackQuestionFinishEvent(
            this.props.surveyVersion,
            this.props.page.property,
            this.props.page.pageStructure,
        );

        const { submitType } = this.props.page;

        const shouldUpdateOrCreateUser =
            validator.equals(submitType, SURVEY_PAGE_SUBMIT_TYP.create) ||
            validator.equals(submitType, SURVEY_PAGE_SUBMIT_TYP.update);

        if (shouldUpdateOrCreateUser) {
            this.setState({
                createContactError: undefined,
            });

            this.createOrUpdateUserContact({
                nextPage,
                currentPage,
            });

            return;
        }

        this.jumpToSurveyPage(nextPage);
    };

    onPreviousSurveyPage = ({ prevPage, currentPage }) => {
        if (prevPage && currentPage) {
            SurveyTrackerInstance.trackSurveyBackEvent(this.props.surveyVersion, currentPage);

            if (prevPage === SURVEY_WORKFLOW_PAGE_INDICES.historyBack) {
                openHistoryBack();
            } else {
                this.jumpToSurveyPage(prevPage);
            }
        }
    };

    render() {
        const { type, progressStep } = this.props.page;

        const PageContent = this.contentPages[type];

        return (
            <>
                <Header />
                <StyledSurveyNewWrapper>
                    <ProgressBar activeStep={progressStep} steps={this.props.pageSize} />

                    <PageContent
                        user={this.props.user}
                        setProperty={this.props.setProperty}
                        getTopOffer={this.props.getTopOffer}
                        topOffer={this.props.topOffer}
                        engine={this.props.engine}
                        isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                        /* Convenient Current page data */
                        page={this.props.page}
                        /* Survey */
                        surveyVersion={this.props.surveyVersion}
                        notifyAboutUser={this.props.notifyAboutUser}
                        /* Actions to change the value & errors of page components */
                        setPageComponentValue={this.props.setPageComponentValue}
                        setPageComponentError={this.props.setPageComponentError}
                        /* Current state of the survey workflow config*/
                        workflow={this.props.workflow}
                        /* Handler for moving to the next page */
                        onNextSurveyPage={this.onNextSurveyPage}
                        /* Handler for moving to the prev page */
                        onPreviousSurveyPage={this.onPreviousSurveyPage}
                        /* Show error after creating contact failed */
                        createContactError={this.state.createContactError}
                    />
                </StyledSurveyNewWrapper>
                <BankLogos />
                <TrustFooter />
                {this.props.page.meta.ui.hasStickyFooter && <StickyFooterPlaceholder />}
            </>
        );
    }
}

const StickyFooterPlaceholder = styled.div`
    background: #fff;
    height: 80px;
`;

const StyledSurveyNewWrapper = styled(SurveyNewWrapper)`
    padding-top: 36px;
    padding-bottom: 54px;
`;

export default withTranslation()(Page);

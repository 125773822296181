export const Answer = {
    Conditional: {
        YES: "Ja",
        NO: "Nein",
        UNKNOWN: "Noch nicht entschieden",
    },
    GOAL_TODAY: {
        LOOK_AROUND: "Nur umschauen/informieren",
        COMPARE: "Mein bestehendes Angebot vergleichen",
        FREE_OFFERS: "Bankangebote kostenlos mit miracl einholen",
        CALL_EXPERT: "Unverbindliches Gespräch mit Experten",
        OTHER: "Oder sage es mit eigenen Worten",
    },
    OFFER: {
        BEST_PRICE: "Angebot mit Bestpreisgarantie einholen",
    },
    INTENTION: {
        BUYING_PROPERTY: "Kauf einer Immobilie",
        OWN_CONSTRUCTION: "Bauvorhaben",
        RE_FINANCING: "Umschuldung",
        GENOSSENSCHAFT_CONTRIBUTION: "Genossenschaftsbeitrag",
        RENOVATION: "Umbau/Renovierung",
        OTHER: "Sonstiges",
    },
    USAGE: {
        OWN: "Eigennutzung",
        RENT: "Zur Vermietung",
    },
    PAX: {
        ONE_PERSON: "1 Person",
        TWO_PERSONS: "2 Personen",
    },
    PROGRESS: {
        LOOKING_FOR_PROPERTY: "Immobiliensuche gestartet",
        ACTIVE_VISITTING: "Aktiv auf Besichtigungen",
        ABOUT_TO_SEND_OFFER: "Ich will ein Kaufanbot stellen",
        OFFER_SENT: "Kaufanbot schon gestellt",
        RESEARCH: "Ich informiere mich nur",
        OWN_CONSTRUCTION: {
            PROPERTY_LAND_AVAILABLE: "Grundstück vorhanden",
            LOOKING_FOR_PROPERTY_LAND: "Auf der Suche nach einem Grundstück",
        },
        REFINANCING: {
            RESEARCH: "Ich informiere mich nur",
            CONCRETE_REFINANCING: "Konkrete Umschuldung",
        },
    },
    AD_PROGRESS: {
        OTHER: "Weiteres/Sonstiges",
    },
    URGENCY: {
        NOW: "So schnell wie möglich",
        WITHIN_3_MONTHS: "Innerhalb von 3 Monaten",
        WITHIN_3_MONTHS_OR_LATER: "3 Monate oder später",
        RESEARCHING: "Ich möchte mich nur informieren",
    },
    AD_INTENTION_OTHER: {
        CONSUMER_CREDIT: "Konsumkredit/Privatkredit",
        REFINANCING: "Umschuldung Konsumkredit/Privatkredit",
        VACATION_HOME: "Ferienimmobilie",
        GENOSSENSCHAFT: "Genossenschaftsbeitrag",
        BUSINESS_CREDIT: "Gewerbliche Finanzierung",
        OTHER: "Oder sage es mit eigenen Worten",
    },
    CALCULATION_INTENTION: {
        NO__CALCULATE_NOW: "Nein, jetzt berechnen",
    },
    CONTRACT: {
        PROPERTY_DEVELOPER: "Bauträger",
        SELLER: "Verkäufer",
        FREE_SELECTION: "Frei wählbar",
        BUYER: "Ich als Käufer",
        UNKNOWN: "Weiß ich nicht",
    },
    VEHICLES: {
        NONE: "Keines",
        ONE: "1 Auto",
        TWO: "2 Autos",
    },
    OCCUPATION: {
        EMPLOYEE: "Angestellt",
        INDEPENDENT: "Selbständig",
        WORKER: "Arbeiter",
        OFFICIAL: "Beamter",
        FREE_LANCER: "Freiberufler",
        PENSIONER: "Pensionist",
        OTHER: "Sonstige",
    },
    FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS: {
        VARIABEL: "Variabel",
    },
    SPEED_APPROVAL: {
        EXPRESS: "Express Anfrage",
        THREE_MONTHS: "3 Monate",
        THREE_SIX_MONTHS: "3 - 6 Monate",
        UP_TO_A_YEAR: "erst nach einem Jahr",
    },
    STATE: {
        WIEN: "Wien",
        NIEDEROESTERREICH: "Niederösterreich",
        OBEROESTERREICH: "Oberösterreich",
        SALZBURG: "Salzburg",
        TIROL: "Tirol",
        VORARLBERG: "Vorarlberg",
        BURGENLAND: "Burgenland",
        STEIERMARK: "Steiermark",
        KAERNTEN: "Kärnten",
        OTHER: "Sonstiges",
    },
    DAY_PICKER: {
        NOW: "Jetzt",
    },
};

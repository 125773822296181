const CommonProperties = {
    USAGE: "art_der_immobiliennutzung",
    EMAIL_COMMUNICATION_ONLY: "kommunikation_nur_per_mail",
    GOAL_TODAY: "goal_today",
    OTHER_GOAL_TODAY: "goal_today_other",
    INTENTION: "kaufvorhaben",
    OFFER_BEST_PRICE_GUARANTEE: "bestpreis_angebot_offer",
    OTHER_INTENTION: "kaufvorhaben_sonstiges__survey_",
    PROGRESS: "need",
    PROGRESS_OWN_CONSTRUCTION: "need_bauvorhaben",
    PROGRESS_REFINANCING: "need_umschuldung",
    AD_LOOKING_FOR_PROPERTY: "ad_immobiliensuche_gestartet",
    AD_LOOKING_FOR_PROPERTY_OTHER: "ad_immobiliensuche_gestartet_sonstiges",
    AD_ACTIVE_VISITTING: "ad_aktiv_auf_besichtigungen",
    AD_ACTIVE_VISITTING_OTHER: "ad_aktiv_auf_besichtigungen_sonstiges",
    AD_ABOUT_TO_SEND_OFFER: "ad_will_kaufanbot_stellen",
    AD_ABOUT_TO_SEND_OFFER_OTHER: "ad_will_kaufanbot_stellen_sonstiges",
    AD_OFFER_SENT: "ad_kaufanbot_gestellt",
    AD_OFFER_SENT_OTHER: "ad_kaufanbot_gestellt_sonstiges",
    AD_RESEARCH: "ad_informiere_mich_nur",
    AD_RESEARCH_OTHER: "ad_informiere_mich_nur_sonstiges",
    AD_OWN_CONSTRUCTION: "ad_bauvorhaben_wann",
    AD_REFINANCING: "ad_umschuldung_wann",
    AD_INTENTION_OTHER: "ad_anderes_vorhaben",
    AD_INTENTION_OTHER_OWN_WORDS: "ad_sonstiges_eigene_worte",
    AD_BUY_URGENCY: "ad_kaufen_wann",
    AGENT: "immobilienmakler",
    ZIP: "plz_immobilie",
    PAX: "anzahl_kn",
    HOUSEHOLD: "selber_haushalt",
    REAL_STATE: "immobilieneigentum_in_der_familie_vorhanden",
    REAL_STATE_FOUND: "wunschimmobilie_gefunden",
    STATE: "bundesland",
    PRIMARY_PERSON_INCOME: "monatliches_nettoeinkommen",
    SECONDARY_PERSON_INCOME: "monatliches_nettoeinkommen_kn2",
    TOTAL_INCOME: "haushaltseinkommen",
    HOUSE_BANK: "hausbank",
    CONTRACT: "kaufvertragserrichtung",
    INTERESTED_IN_CONTRACT_SERVICE: "kaufvertragserrichtung_interesse__survey_",
    ADDITIONAL_LOANS: "bestehende_verbindlichkeiten",
    ADDITIONAL_LOANS_NUMBER: "bestehende_verbindlichkeiten_rate",
    FULL_NAME: "username",
    FIRST_NAME: "firstname",
    LAST_NAME: "lastname",
    AGE: "alter_kreditnehmer_in",
    OCCUPATION: "job_position",
    PHONE: "phone",
    PHONE_CONTRACT_SERVICE: "phone_contract",
    PHONE_UNFORMATTED: "telefonnummer",
    EMAIL: "email",
    AVAILABILITY_DAY: "varfuegbarkeit_tag",
    AVAILABILITY_TIME: "varfuegbarkeit_zeit",
    LOAN_AMOUNT: "kreditbetrag",
    LOAN_AMOUNT_BUYING: "kreditbetrag_kauf",
    REFINANCING_DEBT_LEFT_AMOUNT: "restschuld_hypothekardarlehen",
    MARKETING: "marketing_subscription",
    AGREEMENT: "mit_dem_absenden_dieser_anfrage_habe_ich_die_datenschutzerklarung__agb_und_das_standardinformations",
    AVAILABLE_CAPITAL: "eigenkapital",
    PRICE: "kaufpreis_der_immobilie",
    EXPECTED_PRICE: "gewuenschter_kaufpreis",
    AGENT_TAX: "vermittlungsgebuhr_immobilientreuhander",
    TAX: "grunderwerbssteuer_finanzamt",
    REGISTER_ENTRY: "grundbucheintragungsgebuhr",
    NOTARY: "kaufvertragserrichtungsgebuhr_inkl__treuhandschaft",
    ADDITIONAL_COSTS: "gesamtsumme_baukosten",
    LOAN_AMOUNT_SUMMARY: "auszahlungsbetrag",
    LTV: "ltv__survey_",
    DSTI: "dsti__survey_",
    FIXED_RUNTIME_IN_YEARS: "laufzeit_gesamt",
    FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS: "laufzeit_fixzins",
    TOP_OFFER: "topoffer1__survey_",
    TOP_OFFER_FIXED_RATE_VALUE: "topoffer1_fixzinssatz",
    TOP_OFFER_VAR_RATE_VALUE: "topoffer1_varzinssatz",
    TOP_OFFER_MONTHLY_RATE_VALUE: "topoffer1_rate",
    TOP_OFFER_CREDIT_AMOUNT_VALUE: "topoffer1_kreditbetrag",
    TOP_OFFER_CREDIT_RUN_TIME: "topoffer1_gesamtlaufzeit_number",
    TOP_OFFER_CREDIT_FIXED_RUN_TIME: "topoffer1_fixzinsperiode_number",
    TOP_OFFER_CREDIT_VAR_RUN_TIME: "topoffer1_varzinsperiode_number",
    SURVEY_FLAG: "survey_old_new",
    SURVEY_VERSION: "survey_version",
} as const;

export type CommonPropertiesKeys = keyof typeof CommonProperties;
export type CommonPropertiesValues = (typeof CommonProperties)[CommonPropertiesKeys];

export default CommonProperties;
